<template>
  <div class="body1">
    <img src="../assets/lt.jpg" style="height: 100%;width: 100%;" alt=""/>
  </div>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";


import axios from "axios";

export default {
  name: "index",
  created() {
    console.log(11111111)
    axios.get("https://api.hangzhouluofeng.com:8081/api/data/push?type=3").then(res => {
    })
  }
};
</script>

<style lang="less">
.body1 {
  max-width: 750px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  opacity: 0;
}
</style>
